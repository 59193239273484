import TEditor from './TEditor.vue';
import vueMarkdown from './vueMarkdown.vue';
export default {
  components: {
    TEditor
  },
  setup() {},
  data() {
    return {
      currentRow: {},
      isNewSecondName: true,
      secondNameList: [],
      editColumns: [{
        name: 'course_content',
        type: 'TEditor',
        title: '内容'
      }],
      currentColumns: [{
        name: 'course_name',
        title: '标题',
        isNull: 1
      }, {
        name: 'second_name',
        title: '二级标题',
        type: 'select-text'
      }, {
        name: 'course_type',
        title: '类别',
        type: 'radio',
        radiOptions: ['java', 'nuxt', 'nodejs', 'mongoose', 'spring', 'mysql', 'springcloud', 'vue', 'js', 'css', 'html', 'react', 'go', 'netty', 'docker', 'es', 'python', 'go', 'uniapp', 'TypeScript']
      }]
    };
  },
  mounted() {
    //传参与接受参数
    console.log(this.$route.params.id);
    if (this.$route.params.id && this.$route.params.id !== '0' && this.$route.params.id !== 0) {
      //获取单个course信息
      this.currentRow._id = this.$route.params.id;
      this.getCourseByIdForAdmin();
    }
  },
  methods: {
    async getCourseByIdForAdmin() {
      const res = await this.withLoading(this.apiAll2({
        _id: this.currentRow._id
      }, 'getCourseByIdForAdmin'));
      if (res) {
        this.currentRow = res;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    newCourse() {
      this.confirm('确认新增教程吗？', () => {
        this.currentRow = {};
      });
    },
    //保存用户信息
    async submit() {
      if (!this.currentRow.course_name) {
        this.errorMsgBox('标题不能为空！');
        return false;
      }
      if (!this.currentRow.course_content) {
        this.errorMsgBox('内容不能为空！');
        return false;
      }
      //编辑
      let data = {};
      let params = {};
      let list = [];
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll2(this.currentRow, 'saveCourse'));
      if (res) {
        this.successMsgBox('新建成功！');
        this.currentRow._id = res._id;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    getContent(content) {
      this.currentRow.course_content = content;
    },
    async getCourseSecondName() {
      const res = await this.withLoading(this.apiAll2({
        course_type: this.currentRow.course_type
      }, 'getCourseSecondName'));
      this.secondNameList = res;
    }
  }
};