import TEditor from "./TEditor.vue";
export default {
  components: {
    TEditor
  },
  created() {
    this.getInit();
  },
  data() {
    return {
      size: "meduim",
      tableMaxHeight: "",
      editorValue: "",
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      wxArticleshow: false,
      article: {},
      currentRow: {},
      editType: "",
      currentColumns: [],
      queryModel: {},
      categoryOptions: {
        java: ["基础", "io", "集合", "多线程", "锁", "队列", "JVM", "网络", "web", "设计模式"],
        go: ["基础", "高级"],
        python: ["基础", "高级"],
        前端: ["vue", "react", "js", "css", "html", "uniapp", "前端项目", "typeScript"],
        运维: ["linux", "nginx", "docker", "网络与安全"],
        数据库: ["mysql", "redis", "MongDB", "postgreSQL"],
        微服务: ["springcloud", "springcloud alibaba"],
        消息中间件: ["kafka", "rocketMq", "dubbo", "zookeeper"],
        框架: ["springboot", "springmvc", "jpa", "mybatis", "maven", "git"],
        数据结构与算法: ["数组与链表", "树结构", "排序算法", "哈希表", "加密算法", "全局ID", "队列", "常用数据结构"],
        综合: ["前端项目", "后端项目", "简历完善", "面试心得"]
      },
      category_id_options: ["java", "微服务", "消息中间件", "数据库", "框架", "数据结构与算法", "项目", "前端", "运维"],
      totalColumns: [{
        name: "category_id",
        title: "类别",
        isNull: 1,
        type: "radio",
        radiOptions: ["java", "python", "go", "后端项目综合", "微服务", "消息中间件", "数据库", "框架", "数据结构与算法", "前端", "运维"]
      }, {
        name: "second_category_id",
        title: "二级类别",
        type: "dynamicRadio"
      }, {
        name: "title",
        title: "标题",
        isNull: 1
      }, {
        name: "content",
        title: "内容",
        type: "tEditor"
      }],
      totalInfoColumns: [
      // {
      //   name: "category_id",
      //   title: "类别",
      //   isNull: 1,
      //   type: "radio",
      //   radiOptions: ["基础", "数据库", "框架&中间件", "项目"],
      // },
      // {
      //   name: "second_category_id",
      //   title: "二级类别",
      // },
      {
        name: "title",
        title: "标题",
        isNull: 1
      }, {
        name: "content",
        title: "内容",
        type: "tEditor"
      }]
    };
  },
  methods: {
    getInit() {
      //初始化
      this.tableMaxHeight = window.innerHeight - 210;
      this.queryModel = {};
      this.getMyAccount();
    },
    getContent(content) {
      this.currentRow.content = content;
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      if (Object.keys(this.queryModel).length !== 0) {
        params.ephemeralParam = this.queryModel;
      }
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getInterview"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    openEdit(row, editType) {
      this.accountDialogShow = true;
      this.editType = editType;
      if (editType == 1) {
        this.currentRow = row;
        this.editorValue = this.currentRow.content;
        this.currentRow.imgUrlList = [];
        this.currentRow.companyLogoList = [];
        this.currentColumns = this.totalInfoColumns;
      } else if (editType == 2) {
        //新增账户，清空数据
        this.currentRow = {};
        this.currentColumns = this.totalColumns;
      }
    },
    async addArticle() {
      let res;
      let params = {};
      params.ephemeralParam = this.article;
      let data = {};
      data.params = JSON.stringify(params);
      res = await this.withLoading(this.apiAll(data, "/addArticle"));
      if (res.code >= 0) {
        this.successMsgBox(res.message);
        this.wxArticleshow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit() {
      const formData = new FormData();
      let res;
      let params = {};
      params.ephemeralParam = this.currentRow;
      formData.append("params", JSON.stringify(params));
      res = await this.withLoading(this.formDataApi(formData, "/saveInterview"));
      if (res.code >= 0) {
        this.successMsgBox(res.message);
        this.getInit();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    searchData() {
      alert("这里是搜索！");
    },
    clearSearch() {
      alert("清除所有搜索条件！");
    },
    handleSelectionChange(val) {
      alert("选中数据！");
    }
  }
};