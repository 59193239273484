import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-247d2cbc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 2
};
const _hoisted_2 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vueMarkdown = _resolveComponent("vueMarkdown");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
    style: {
      "text-align": "center",
      "padding": "10px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 20
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form, null, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.editColumns, i => {
          return _openBlock(), _createBlock(_component_el_form_item, {
            prop: i.name,
            required: i.isNull,
            key: i.name,
            error: "必填",
            "show-message": false
          }, {
            default: _withCtx(() => [_withDirectives(_createVNode(_component_vueMarkdown, {
              ref_for: true,
              ref: "vueMarkdownRef",
              style: {
                "height": "calc(100vh - 120px)",
                "width": "100%",
                "box-shadow": "none"
              },
              value: $data.currentRow[i.name],
              onGetContent: $options.getContent
            }, null, 8, ["value", "onGetContent"]), [[_vShow, !_ctx.isEditor]])]),
            _: 2
          }, 1032, ["prop", "required"]);
        }), 128))]),
        _: 1
      })]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 4
    }, {
      default: _withCtx(() => [_createVNode(_component_el_form, {
        "label-width": "100px",
        "label-position": "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: ""
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            icon: "CircleCheck",
            size: _ctx.size,
            type: "primary",
            onClick: $options.submit
          }, {
            default: _withCtx(() => [_createTextVNode("提交")]),
            _: 1
          }, 8, ["size", "onClick"]), _createVNode(_component_el_button, {
            icon: "CircleCheck",
            size: _ctx.size,
            type: "primary",
            onClick: $options.newCourse
          }, {
            default: _withCtx(() => [_createTextVNode("再写一篇")]),
            _: 1
          }, 8, ["size", "onClick"])]),
          _: 1
        }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.currentColumns, i => {
          return _openBlock(), _createBlock(_component_el_form_item, {
            prop: i.name,
            label: i.title,
            required: i.isNull,
            key: i.name,
            error: "必填",
            "show-message": false
          }, {
            default: _withCtx(() => [i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
              key: 0,
              modelValue: $data.currentRow[i.name],
              "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event,
              onChange: $options.getCourseSecondName
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.radiOptions, (i, index) => {
                return _openBlock(), _createBlock(_component_el_radio, {
                  label: i,
                  key: index,
                  value: i
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])) : i.type == 'textarea' ? (_openBlock(), _createBlock(_component_el_input, {
              key: 1,
              size: _ctx.size,
              type: "textarea",
              rows: 10,
              placeholder: i.desc,
              modelValue: $data.currentRow[i.name],
              "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
            }, null, 8, ["size", "placeholder", "modelValue", "onUpdate:modelValue"])) : i.type == 'select-text' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [$data.secondNameList && $data.secondNameList.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_el_button, {
              text: "",
              type: "primary",
              onClick: _cache[0] || (_cache[0] = $event => $data.isNewSecondName = !$data.isNewSecondName)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString($data.isNewSecondName ? '选择' : '新增'), 1)]),
              _: 1
            }), $data.isNewSecondName ? (_openBlock(), _createBlock(_component_el_select, {
              key: 0,
              modelValue: $data.currentRow[i.name],
              "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.secondNameList, (i, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: i,
                  value: i
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(i), 1)]),
                  _: 2
                }, 1032, ["label", "value"]);
              }), 128))]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"])) : (_openBlock(), _createBlock(_component_el_input, {
              key: 1,
              style: {
                "display": "inline"
              },
              size: _ctx.size,
              placeholder: i.desc,
              modelValue: $data.currentRow[i.name],
              "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
            }, null, 8, ["size", "placeholder", "modelValue", "onUpdate:modelValue"]))])) : (_openBlock(), _createBlock(_component_el_input, {
              key: 1,
              size: _ctx.size,
              placeholder: i.desc,
              modelValue: $data.currentRow[i.name],
              "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
            }, null, 8, ["size", "placeholder", "modelValue", "onUpdate:modelValue"]))])) : (_openBlock(), _createBlock(_component_el_input, {
              key: 3,
              size: _ctx.size,
              placeholder: i.desc,
              modelValue: $data.currentRow[i.name],
              "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
            }, null, 8, ["size", "placeholder", "modelValue", "onUpdate:modelValue"]))]),
            _: 2
          }, 1032, ["prop", "label", "required"]);
        }), 128))]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  })]);
}